.checkbox {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

input.hike-switch {
    position: relative;
    -webkit-appearance: none;
    outline: none;
    width: 50px;
    height: 30px;
    background-color: #fff;
    border: 1px solid #D9DADC;
    border-radius: 50px;
    box-shadow: inset -20px 0 0 0 #fff;
  }
  
  input.hike-switch:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    background: transparent;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    box-shadow: 2px 4px 6px rgba(0,0,0,0.2);
  }
  
  input.hike-switch:checked {
    box-shadow: inset 20px 0 0 0 #1abc9c;
    border-color: #1abc9c;
  }
  
  input.hike-switch:checked:after {
    left: 20px;
    box-shadow: -2px 4px 3px rgba(0,0,0,0.05);
  }

/*styles only for laptop and horizontal tablets*/
@media only screen and (min-width: 769px) and (max-width: 1800px) {
    .checkbox label {
        font-size: 14px;
    }
}