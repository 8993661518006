html, body {
  margin: 0; padding: 0;
  overscroll-behavior-y: contain;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.app {
  position: relative;
}

ul {
  margin-top: 24px;
  padding-left: 16px;
}

ul li {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

/*form styles*/
input[type=range] {
  width: 100%;
  height: 50px;
  -webkit-appearance: none;
  background: transparent;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  background: #ddd;
}

input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  background: #1abc9c;
  height: 26px;
  width: 26px;
  margin-top: -11px;
  border-radius: 26px;
  border: 0;
  pointer-events: all;
  position: relative;
  -moz-appearance: none;
  z-index: 1;
  position: absolute;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #1abc9c;
  height: 26px;
  width: 26px;
  margin-top: -11px;
  border-radius: 99px;
  border: 0;
  position: absolute;
  z-index: 2;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 300px;
  height: 4px;
  background: #ddd;
}

button {
  display: block;
  border: 0;
  width: 50%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 50px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 300;
  background: #1abc9c;
  font-family: 'muli', sans-serif;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
}

h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: #333;
  margin-bottom: 2px;
  color: #23395d;
}

h3 {
  margin-top: 32px;
}

p, li, label {
  font-family: 'Muli', sans-serif;
  color: #666;
  line-height: 1.5;
}

strong {
  color: #1abc9c;
  font-weight: 700;
}

p {
  font-size: 16px;
  margin: 0;
}

a {
  text-decoration: underline;
  color: #1abc9c;
  font-weight: 700;
}

span {
  font-family: 'Muli', sans-serif;
  display: block;
  color: #666;
}

a {
  /* color: #aaa; */
  margin-top: 4px;
  text-decoration: underline;
}

/*styles only for laptop and horizontal tablets*/
@media only screen and (min-width: 769px) and (max-width: 1800px) {
  h2 {
    font-size: 18px
  }

  h3 {
    font-size: 16px;
  }

  span, p, ul li {
    font-size: 14px;
  }
}