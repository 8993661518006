.region {
    color: #666;
    font-size: 20px;
    font-weight: 700;
    margin-top: 8px;
}

.coordinates {
    color: #aaa;
    font-weight: 400;
    font-size: 14px;
    margin-top: 8px;
}

.wta-link, .coordinates a {
    margin-top: 4px;
    text-decoration: underline;
}

.length {
    color: #666;
    font-weight: 400;
}

/*styles only for laptop and horizontal tablets*/
@media only screen and (min-width: 769px) and (max-width: 1800px) {
    .region {
      font-size: 16px;
    }

    .coordinates {
        font-size: 12px;
    }
}