.hike-bar {
    height: 36px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1abc9c;
}

.hike-bar-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90%;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 18px;
    margin: 0;
}

.info-icon {
    height: 75%;
}

/*Laptop & horizontal tablet styles and larger*/
@media only screen and (min-width: 769px) {
    .hike-bar {
        background-color: #333;
    }

    .info-icon {
        display: none;
    }
}

/*typeography*/
.hike-bar-title {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 18px;
}