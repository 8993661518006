.rating-container {
    margin-top: 8px;
    width: 100%;
}

.stars-inner {
    color: #1abc9c;
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
    width: 0;
}

.stars-outer {
    color: #1abc9c;
    display: inline-block;
    position: relative;
    font-family: FontAwesome;
}

.fa-star {
    margin-right: 10px;
    margin-left: 10px;
  }
  
.fa-star:first-child {
    margin-left: 0;
}