nav {
    width: 100%;
    height: 64px;
    border-top: #1abc9c 1px solid;
}

.menu {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
}

/*Laptop & horizontal tablet styles and larger*/
@media only screen and (min-width: 769px) {
    nav {
      position: absolute;
      left: 12px;
      max-width: 412px;
      background-color: #fff;
      bottom: 10px;
      border-top: 0;
      border-radius: 5px;
      -webkit-box-shadow: 0px 3px 31px 17px rgba(0,0,0,0.21);
      -moz-box-shadow: 0px 3px 31px 17px rgba(0,0,0,0.21);
      box-shadow: 0px 3px 31px 17px rgba(0,0,0,0.21);
    }
}

/*Desktop Styles*/
@media only screen and (min-width: 1800px) {
    nav {
      max-width: 450px;
    }
}