.menu-item {
    border-right: solid #1abc9c 1px;
    list-style-type: none;
    margin: 0; padding: 0;
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-item:last-child {
    border-right: 0;
}
  
.menu-item-icon {
    width: 50%;
    max-width: 50px;
    cursor: pointer;
}